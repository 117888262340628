import React, { useEffect } from 'react';
import router, { useRouter } from 'next/router';
import Cookies from 'js-cookie';

function NotFound() {

    return (
        <div className='notfound'>
            <h1>404</h1>
            <h2>This page could not be found.</h2>
        </div>
    );
}

export default NotFound;


